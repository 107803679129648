<template>
  <b-card-code>
    <div v-if="$can('create', 'Subject Teacher')" class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal()">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx :data="getData" :columns="columns10" :filename="'filename'" :sheetname="'subjects'">
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData" :csv-title="'My_CSV'">
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->

    <vue-good-table style="white-space: nowrap; 
        text-align: center; 
        vertical-align: middle" 
        :columns="columns" 
        :rows="subject_teachers" 
        :rtl="direction" 
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }" 
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }">



      <template slot="table-row" slot-scope="props">
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'subject_id'">
          {{ subjectByID(props.row.subject_id) }}
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>
        <span v-else-if="props.column.field === 'shift_id'">
          {{ employeeNameById(props.row.employee_id) }}
        </span>
        <!-- <span v-else-if="props.column.field === 'shift_id'">
          {{ shiftByID(props.row.shift_id) }}
        </span> -->
        <span v-else-if="props.column.field === 'wing_id'">
          {{ wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ student_groupByID(props.row.student_group_id) }}
        </span>
        <span v-else-if="props.column.field === 'section_id'">
          {{ getDesiredSections(props.row.section_id) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id) }}
        </span>
        <span v-else-if="props.column.field === 'employee_id'">
          {{ getEmployeeId(props.row.employee_id) }}
        </span>
        <span v-else-if="props.column.field === 'name'">
          {{ employeeNameById(props.row.employee_id) }}
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox v-if="$can('status change', 'Subject Teacher')"
              :name="'check-button' + props.row.originalIndex" :id="'check-button' + props.row.originalIndex"
              @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active ? 'Active' : 'Deactivated' }}
            </b-badge>
          </template>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
              <!--              <b-button v-if="!props.row.is_approved" :variant="'outline-primary'" @click="approve(props.row.id)">
                {{ 'Approve' }}
              </b-button>-->
              <b-button v-if="$can('update', 'Shift')" @click="showModificationModal(props.row)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-login variant="primary">
                <feather-icon icon="Edit2Icon" class="mr-50" />
              </b-button>
              <b-button v-if="$can('remove', 'Subject Teacher')" :variant="'danger'"
                @click="remove(props.row.id, props.row.originalIndex)">
                <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['10', '15', '20', '50', '100', '500']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>


    <!-- modal to assign a new teacher for a subject-->
    <b-modal ref="my-modal" size="lg" hide-footer :title="'Subject Teacher Assignment'">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_subject_teacher">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="class" rules="required">
                <select name="classes_id" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{ cls.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Version </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="version" rules="required">
                <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">{{ version.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Shift </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="shift" rules="required">
                <select name="shift_id" v-model="shift_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">{{ shift.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Group </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="student group" rules="required">
                <select name="student_group_id" v-model="student_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(group, key) in student_groups" :value="group.id" :key="key">{{ group.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Subject </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="subject" rules="required">
                <select name="subject_id" v-model="subject_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(subject, key) in subjects" :value="subject.id" :key="key">{{ subject.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Employee </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="employee" rules="required">
                <select name="employee_id" v-model="employee_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(emp, key) in teachers" :value="emp.id" :key="key">{{ getEmployeeId(emp.id) ?
                      getEmployeeId(emp.id) : 'TBD'
                  }} - {{ employeeNameById(emp.id) }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Wing </span>
              </b-card-text>
              <div>
                <b-form-checkbox v-for="(wing, key) in wings" name="student_wings[]" :value="wing.id"
                  v-model="wing_list" :key="key">{{ wing.name }}</b-form-checkbox>
              </div>
            </b-col>


            <b-col md="4">
              <b-card-text>
                <span>Select Academic Year </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="academic year" rules="required">
                <select name="academic_year_id" v-model="academic_year_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">{{ aYear.year }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Section </span>
              </b-card-text>
              <div>
                <b-form-checkbox v-for="(section, key) in sections" name="student_sections[]" :value="section.id"
                  :key="key">{{ section.name }}</b-form-checkbox>
              </div>

            </b-col>

            <!-- submit button -->
            <b-col md="4" class="mt-2">
              <b-button variant="primary" type="submit" @click.prevent="assignSubjectTeacher">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>



    <!-- Modal window to modify an assigned teacher for a subject-->
    <b-modal ref="modification-modal" size="lg" hide-footer :title="'Modify Assigned Subject Teacher'">

      <validation-observer ref="simpleRules">
        <form ref="modify_subject_teacher">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="class" rules="required">
                <select name="modifying_class_id" v-model="selected_row.classes_id" @change="handleChange"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{ cls.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Version </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="version" rules="required">
                <select name="version_id" v-model="selected_row.version_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(version, key) in vs" :value="version.id" :key="key">{{ version.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Shift </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="shift" rules="required">
                <select name="shift_id" v-model="selected_row.shift_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(shift, key) in sf" :value="shift.id" :key="key">{{ shift.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Group </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="student group" rules="required">
                <select name="student_group_id" v-model="selected_row.student_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(group, key) in sg" :value="group.id" :key="key">{{ group.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Subject </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="subject" rules="required">
                <select name="subject_id" v-model="selected_row.subject_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(subject, key) in modifySubjectList" :value="subject.id" :key="key">{{ subject.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Employee </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="employee" rules="required">
                <select name="employee_id" v-model="selected_row.employee_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(emp, key) in teachers" :value="emp.id" :key="key">{{ getEmployeeId(emp.id) ?
                      getEmployeeId(emp.id) : 'TBD'
                  }} - {{ employeeNameById(emp.id) }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Wing </span>
              </b-card-text>
              <div>
                <b-form-checkbox v-for="(wing, key) in wg" name="student_wings[]" :value="wing.id"
                  v-model="selectedWings" :checked="true" :key="key">{{ wing.name }}</b-form-checkbox>
              </div>
            </b-col>


            <b-col md="4">
              <b-card-text>
                <span>Select Academic Year </span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="academic year" rules="required">
                <select name="academic_year_id" v-model="selected_row.academic_year_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">{{ aYear.year }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Section </span>
              </b-card-text>
              <div>
                <b-form-checkbox v-model="selectedSections" v-for="(section, key) in sections" name="student_sections[]" :value="section.id"
                :checked="true" :key="key">{{ section.name }}</b-form-checkbox>
              </div>

            </b-col>

            <!-- submit button -->
            <b-col md="4" class="mt-2">
              <b-button variant="primary" type="submit" @click.prevent="modifySubjectTeacher">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import moment from "moment";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name: 'ClassTeacher',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton, BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BRow, BCol, BCardText, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      pageLength: 10,
      dir: false,
      wing_list: [],
      group_list: [],
      section_list: [],
      selected_row: {},
      selectedSections: [],
      selectedWings: [],
      student_wings: [],
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Subject',
          field: 'subject_id',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        {
          label: 'Employee Id',
          field: 'employee_id',
        },
        {
          label: this.$t('Teacher Name'),
          field: "name",
        },
        {
          label: 'Student Group',
          field: 'student_group_id',
        },
        {
          label: 'Section',
          field: 'section_id',
        },
        {
          label: 'Academic year',
          field: 'academic_year_id',
        },

        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: "",
      integer,
      classes: [],
      versions: [],
      shifts: [],
      student_groups: [],
      modifySubjectList:[],
      wings: [],
      all_sections: [],
      map_sections: [],
      student_group_id: null,
      version_id: null,
      shift_id: null,
      wing_id: null,
      classes_id: null,
      academic_year_id: null,
      subject_id: null,
      modifying_class_id: null,
      section_id: null,
      employee_id: null,
      columns10: [
        {
          label: 'Subject',
          field: 'Subject',
        },
        {
          label: 'Class',
          field: 'Class',
        },
        {
          label: 'Wing',
          field: 'Wing',
        },
        {
          label: 'Student Group',
          field: 'StudentGroup',
        },
        {
          label: 'Section',
          field: 'Section',
        },
        {
          label: 'Academic year',
          field: 'AcademicYear',
        },
        {
          label: 'Employee Id',
          field: 'employee_id',
        },
        {
          label: 'employee Name',
          field: 'EmployeeName',
        }
      ],
    }
  },
  methods: {
    filterFn: function (data, filterString) {
      return data === filterString;
    },

    handleChange(){
      this.filterSubjects();
     
    },

    filterSubjects(){
      this.modifySubjectList = this.$store.getters.subjects.filter(item => {
        if (item.classes_id == this.selected_row.classes_id) {
          return item;
        }
      });

      
    },
    showModificationModal(row) {
      this.selected_row = row;
      this.filterSubjects();
      this.selectedSections = [];
      this.selectedWings = [];
      const sectionArray = this.selected_row.section_id.split(',');

      for (let i = 0; i < sectionArray.length -1; i++) {
        this.selectedSections.push(...sectionArray[i]);
      }

      const wingArray = this.selected_row.wing_id.split(',');
      for (let i = 0; i < wingArray.length -1; i++) {
        this.selectedWings.push(...wingArray[i]);
      }
     
      this.$refs['modification-modal'].show();
    },
    onClick(evt) {
      if (evt.target.checked && this.section_list.indexOf(evt.target.value) == -1) {
        this.section_list.push(evt.target.value);
      }
    },
    pdfGenerate() {
      let title = "Subject Teacher";
      let clm = ['Subject', 'Class', 'Wing', 'StudentGroup', 'Section', 'AcademicYear', 'EmployeeId', 'EmployeeName'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, { responseType: 'blob' }).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'subject_teacher.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
    /*
    *
    * method for status change item
    * */
    statusChange(id, index) {
      apiCall.put(`/subject/teacher/status/change${id}`).then((response) => {
        if (response.data.status === 'success') {
          this.$toaster.success(response.data.message);
          this.$store.commit('STATUS_CHANGE_SUBJECT_TEACHER', index);
        } else {
          this.$store.dispatch('GET_ALL_SUBJECT_TEACHER');
        }
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },

    /*
    * method for remove item
    * */
    remove(id, index) {
      this.$swal({
        title: 'Are you sure?',
        text: "You want to remove this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/subject/teacher/remove${id}`).then((response) => {
            this.$toaster.success(response.data.message);
            this.$store.commit('REMOVE_SUBJECT_TEACHER', index);
          }).catch((error) => {
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    /*
    * method for show modal
    * */
    showModal() {
      this.student_group_id = null;
      this.version_id = null;
      this.shift_id = null;
      this.wing_id = null;
      this.classes_id = null;
      this.academic_year_id = null;
      this.employee_id = null;
      this.subject_id = null;
      this.section_id = null;
      this.$refs['my-modal'].show();
    },
    /*
    * method for hide modal
    * */
    hideModal() {
      this.$refs['my-modal'].hide();
      this.$refs['modification-modal'].hide();
      location.reload();
    },
    /*
    * method for store item
    * */
    assignSubjectTeacher() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.add_subject_teacher);
          apiCall.post('/subject/teacher/store', data).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              this.$refs.simpleRules.reset();
              this.$store.commit('ADD_SUBJECT_TEACHER', response.data.data);
              this.hideModal();
            } else if (response.data.status === 'restore') {
              this.$toaster.success(response.data.message);
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SUBJECT_TEACHER');
              this.hideModal();
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.data.status == 422) {
              let mainData = '';
              error.response.data.errors.forEach((item) => {
                mainData += item + "<br/>";
              });
              this.$toaster.error(mainData);
            } else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },

    modifySubjectTeacher() {
      
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.modify_subject_teacher);
          apiCall.post(`/subject/teacher/update${this.selected_row.id}`, data).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              // this.$refs.simpleRules.reset();
              // this.$store.commit('ADD_SUBJECT_TEACHER', response.data.data);
              this.hideModal();
            } else if (response.data.status === 'restore') {
              this.$toaster.success(response.data.message);
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SUBJECT_TEACHER');
              this.hideModal();
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.data.status == 422) {
              let mainData = '';
              error.response.data.errors.forEach((item) => {
                mainData += item + "<br/>";
              });
              this.$toaster.error(mainData);
            } else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    /*
    * method for get all active class wise version,student groups
    * */
    async getAll() {
      await apiCall.get('/get/active/class/with/v/s/sg/sections').then((response) => {
        this.classes = response.data;
      }).catch(() => {
        this.classes = [];
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['subject_teachers', 'academic_years', 'employees', 'versionByID',
                    'employeeIdById', 'employeeNameById', 'getEmployeeId', 'shiftByID',
                    'wingByID', 'student_groupByID', 'academic_yearByID', 'classByID', 'subjectByID',
                    'sections', 'sectionById', 'teachers', 'getDesiredSections']),
    getData() {
      return this.subject_teachers.map(item => ({
        Subject: item.subject_id ? this.subjectByID(item.subject_id) : '',
        Class: item.classes_id ? this.classByID(item.classes_id) : '',
        Version: item.version_id ? this.versionByID(item.version_id) : '',
        Shift: item.shift_id ? this.shiftByID(item.shift_id) : '',
        Wing: item.wing_id ? this.wingByID(item.wing_id) : '',
        StudentGroup: item.student_group_id ? this.student_groupByID(item.student_group_id) : '',
        Section: item.section_id ? this.sectionById(item.section_id) : '',
        AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
        EmployeeId: item.employee_id ? this.getEmployeeId(item.employee_id) : '',
        EmployeeName: item.employee_id ? this.employeeNameById(item.employee_id) : '',
        Status: item.is_active ? 'Active' : 'Inactive'
      }));
    },
    cls() { return this.$store.getters.classes },
    vs() { return this.$store.getters.versions },
    sf() { return this.$store.getters.shifts },
    wg() { return this.$store.getters.wings },
    sg() { return this.$store.getters.student_groups },

    subjects() {
      return this.$store.getters.subjects.filter(item => {
        if (item.classes_id == this.classes_id && item.student_group_id == this.student_group_id) {
          return item;
        }
      })
    },
   
  },
  created() {
    this.$store.dispatch('GET_ALL_SUBJECT_TEACHER');
    if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if (this.employees.length < 1) this.$store.dispatch('GET_ALL_EMPLOYEE');
    if (this.teachers.length < 1) this.$store.dispatch('GET_ALL_TEACHER');
    if (this.cls.length < 1) this.$store.dispatch('GET_ALL_CLASSES');
    if (this.vs.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    if (this.sf.length < 1) this.$store.dispatch('GET_ALL_SHIFT');
    if (this.wg.length < 1) this.$store.dispatch('GET_ALL_WING');
    if (this.sg.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.subjects.length < 1) this.$store.dispatch('GET_ALL_SUBJECT');
    if (this.sections.length < 1) this.$store.dispatch('GET_ALL_SECTION');
    this.getAll();
  },
  watch: {
    /*watcher for class on change*/
    classes_id() {
      let s_classes = this.classes.find(item => item.id === this.classes_id);
      this.version_id = null;
      this.shift_id = null;
      this.student_group_id = null;
      this.wing_id = null;
      this.subject_id = null;
      this.section_id = null;
      if (s_classes) {
        this.versions = s_classes.versions ?? [];
        this.shifts = s_classes.shifts ?? [];
        this.wings = s_classes.wings ?? [];
        this.student_groups = s_classes.student_groups ?? [];
        this.all_sections = s_classes.sections ?? [];
      }
      else {
        this.versions = [];
        this.shifts = [];
        this.wings = [];
        this.all_sections = [];
      }
    },
   
    all_sections() {
      this.section_id = null;
      this.map_sections = [];
    },
    wing_id() {
      this.section_id = null;
      if (this.all_sections.length > 0) {
        this.map_sections = this.all_sections.filter(item => {
          if (item.pivot.classes_id === this.classes_id) return item;
        })
      }
      else {
        this.map_sections = [];
      }
    },
    academic_year_id() {
      this.section_id = null;
      if (this.all_sections.length > 0) {
        this.map_sections = this.all_sections.filter(item => {
          if (item.pivot.classes_id === this.classes_id &&
            this.section_list.indexOf(item.pivot.section_id.toString()) >= 0 &&
            item.pivot.academic_year_id === this.academic_year_id) {
            return item;
          }
        })
      }
      else {
        this.map_sections = [];
      }
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>